// extracted by mini-css-extract-plugin
export var carouselContainer = "C_vB d_v d_G d_bd d_Y";
export var carouselContainerCards = "C_vC C_vB d_v d_G d_bd d_Y";
export var carouselContainerSides = "C_vD d_v d_G d_Y";
export var prevCarouselItem = "C_vF d_v d_G d_Z d_j";
export var prevCarouselItemL = "C_vG C_vF d_v d_G d_Z d_j";
export var moveInFromLeft = "C_vH";
export var prevCarouselItemR = "C_vJ C_vF d_v d_G d_Z d_j";
export var moveInFromRight = "C_vK";
export var selectedCarouselItem = "C_vL d_v d_G d_Y d_bd";
export var selectedCarouselItemL = "C_vM C_vL d_v d_G d_Y d_bd";
export var selectedCarouselItemR = "C_vN C_vL d_v d_G d_Y d_bd";
export var nextCarouselItem = "C_vP d_v d_G d_Z d_j";
export var nextCarouselItemL = "C_vQ C_vP d_v d_G d_Z d_j";
export var nextCarouselItemR = "C_vR C_vP d_v d_G d_Z d_j";
export var arrowContainer = "C_vS d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainer = "C_vT C_vS d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainerHidden = "C_vV C_vT C_vS d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var nextArrowContainer = "C_vW C_vS d_G d_Z d_dz d_bk d_3 d_b0";
export var carouselContainerProducts = "C_vX";
export var nextArrowContainerHidden = "C_vY C_vW C_vS d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var arrow = "C_kH d_Z";
export var prevArrow = "C_vZ C_kH d_Z";
export var nextArrow = "C_v0 C_kH d_Z";
export var carouselIndicatorContainer = "C_v1 d_v d_dv d_bk d_by d_bC d_bN d_bH d_Z";
export var btnsContainer = "C_v2 d_v d_by d_bD";
export var carouselText = "C_v3 d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var carouselTextInactive = "C_v4 C_v3 d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var indicator = "C_v5 d_b6";
export var carouselIndicator = "C_v6 C_v5 d_b6";
export var carouselIndicatorSelected = "C_v7 C_v5 d_b6";
export var arrowsContainerTopRight = "C_v8 d_Z d_bk d_bB";
export var arrowsContainerBottomLeft = "C_v9 d_Z d_bk d_bB";
export var arrowsContainerSides = "C_wb d_Z d_bk d_bB";
export var smallArrowsBase = "C_wc d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainer = "C_wd C_wc d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrev = "C_wf C_wd C_wc d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerSmall = "C_wg C_wc d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrevSmall = "C_wh C_wg C_wc d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var icon = "C_pD";
export var iconSmall = "C_wj";
export var multipleWrapper = "C_wk d_bB d_bD d_bd";
export var multipleImage = "C_wl d_bB";
export var sidesPrevContainer = "C_wm C_wd C_wc d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";
export var sidesNextContainer = "C_wn C_wd C_wc d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";