// extracted by mini-css-extract-plugin
export var tileContent = "s_rz d_v d_G d_Y";
export var teamTextLeft = "s_rB d_dt";
export var teamTextCenter = "s_rC d_dv";
export var teamTextRight = "s_rD d_dw";
export var alignLeft = "s_pL d_v d_by d_fn d_bF d_dt";
export var alignCenter = "s_bN d_v d_by d_fp d_bC d_dv";
export var alignRight = "s_pM d_v d_by d_fq d_bG d_dw";
export var teamContainer = "s_rF d_dV";
export var teamContainerFull = "s_rG d_dS";
export var teamRowWrapper = "s_rH d_cb";
export var teamTileWrapper = "s_j3 d_j3 d_Y d_ct";
export var teamTileSquareWrapper = "s_rJ d_j4 d_Y d_ct";
export var teamTileRoundWrapper = "s_j4 d_j4 d_Y d_ct";
export var teamTileNoGuttersWrapper = "s_j5 d_j5 d_Y";
export var teamHoverNoGutters = "s_j6 d_j6 d_Z d_v d_G d_by d_bC d_bN d_bl";
export var teamImageText = "s_kc d_kc d_bk d_v d_bx";
export var teamInfoWrapperSquare = "s_rK d_j7";
export var teamInfoWrapperRound = "s_j7 d_j7";
export var teamInfoWrapper = "s_rL d_j8 d_Z";
export var teamInfoWrapperNoGutters = "s_j9 d_j9 d_v d_G d_bC d_bL d_bH d_c6";
export var teamImgWrapper = "s_jZ d_jZ";
export var teamImgWrapperAlt = "s_j0 d_j0";
export var teamImgWrapperNoGutters = "s_kb d_kb";
export var teamHeader = "s_rM d_cv";
export var teamHeaderAlt = "s_rN d_cv";
export var teamHeaderNoGutters = "s_rP d_cv d_cC";