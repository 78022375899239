// extracted by mini-css-extract-plugin
export var alignLeft = "t_pL d_fn d_bF d_dt";
export var alignCenter = "t_bN d_fp d_bC d_dv";
export var alignRight = "t_pM d_fq d_bG d_dw";
export var testimonialsContainer = "t_rQ d_dV";
export var testimonialsContainerFull = "t_rR d_dS";
export var testimonialsMainHeader = "t_jw d_jw d_v d_cv";
export var testimonialsComponentParagraph = "t_jy d_jy";
export var testimonialsComponentSmall = "t_jx d_jx";
export var testimonialsComponentsWrapper = "t_js d_js d_x d_v d_ct d_b2 d_b4";
export var testimonialsComponentsWrapperDesign2 = "t_jt d_jt d_x d_v d_ct d_b2 d_b4 d_bB";
export var testimonialsComponentsWrapperDesign3 = "t_jv d_jv d_x d_v d_G d_b4 d_bS d_by d_bH d_bN d_bC";
export var testimonialsBackgroundColor = "t_rS d_d6";
export var colEqualHeight = "t_rT d_by";
export var testimonialsColumnWrapper = "t_jz d_jz d_b2";
export var testimonialsImageWrapper = "t_jp d_jp d_v d_Y";
export var testimonialsImageWrapperDesign2 = "t_jq d_jq d_q d_Y d_bw";
export var testimonialsImageWrapperDesign3 = "t_jr d_jr d_Y d_by";
export var design2TextWrapper = "t_rV d_bB d_bN d_bH";
export var design3 = "t_rW d_cC d_G";
export var imageDesign2 = "t_rX";
export var SmallSmall = "t_rY x_rY x_r5 x_ss";
export var SmallNormal = "t_rZ x_rZ x_r5 x_st";
export var SmallLarge = "t_r0 x_r0 x_r5 x_sq";
export var exceptionWeight = "t_rf x_sx";