// extracted by mini-css-extract-plugin
export var alignDiscLeft = "q_qK d_fn d_bF d_dt";
export var alignLeft = "q_pL d_fn d_bF d_dt";
export var alignDiscCenter = "q_qL d_fp d_bC d_dv";
export var alignCenter = "q_bN d_fp d_bC d_dv";
export var alignDiscRight = "q_qM d_fq d_bG d_dw";
export var alignRight = "q_pM d_fq d_bG d_dw";
export var footerContainer = "q_qN d_dV d_bV";
export var footerContainerFull = "q_qP d_dS d_bV";
export var footerHeader = "q_kf d_kf";
export var footerTextWrapper = "q_qQ d_v";
export var footerDisclaimerWrapper = "q_km d_km d_cj";
export var badgeWrapper = "q_qR d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "q_qS undefined";
export var footerDisclaimerLeft = "q_qT undefined";
export var verticalTop = "q_qV d_by d_bH d_bM d_bK";
export var firstWide = "q_qW";
export var disclaimer = "q_qX d_by d_bH";
export var socialDisclaimer = "q_qY";
export var left = "q_qZ";
export var wide = "q_q0 d_cx";
export var right = "q_q1 d_bJ";
export var line = "q_fj d_fk d_ct";
export var badgeDisclaimer = "q_q2 d_bB d_bN d_bH";
export var badgeContainer = "q_q3 d_by d_bG d_bN";
export var badge = "q_q4";
export var padding = "q_q5 d_c6";
export var end = "q_q6 d_bG";
export var linkWrapper = "q_q7 d_dz";
export var link = "q_mC d_dz";
export var colWrapper = "q_q8 d_cw";
export var consent = "q_f d_f d_bB d_bN";
export var media = "q_q9 d_bw d_dx";
export var itemRight = "q_rb";
export var itemLeft = "q_rc";
export var itemCenter = "q_rd";
export var exceptionWeight = "q_rf x_sx";