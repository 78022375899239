// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_rg d_gv d_cr";
export var heroHeaderCenter = "r_gw d_gw d_cr d_dv";
export var heroHeaderRight = "r_gx d_gx d_cr d_dw";
export var heroParagraphLeft = "r_rh d_gr d_cv";
export var heroParagraphCenter = "r_gs d_gs d_cv d_dv";
export var heroParagraphRight = "r_gt d_gt d_cv d_dw";
export var heroBtnWrapperLeft = "r_rj d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "r_rk d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "r_rl d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "r_rm d_gq d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "r_rn d_gp d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "r_rp x_rp";
export var heroExceptionNormal = "r_rq x_rq";
export var heroExceptionLarge = "r_rr x_rr";
export var Title1Small = "r_rs x_rs x_r5 x_r6";
export var Title1Normal = "r_rt x_rt x_r5 x_r7";
export var Title1Large = "r_rv x_rv x_r5 x_r8";
export var BodySmall = "r_rw x_rw x_r5 x_sp";
export var BodyNormal = "r_rx x_rx x_r5 x_sq";
export var BodyLarge = "r_ry x_ry x_r5 x_sr";